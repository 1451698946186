// Input for image
<template>
  <div class="input-img">
    <v-text-field
      dense
      hide-details
      outlined
      class="base-text-field"
      v-model="nameFile"
    />
    <label class="input-img__fake-btn">
      <input
        class="input-img__btn"
        type="file"
        ref="imageFile"
        @change="handleFileUpload()"
      />
      <span>...</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "InputImage",
  props: {
    value: {},
  },
  data: () => ({
    imageFile: null,
    fileInfo: [],
    nameFile: "Выберите файл...",
  }),
  methods: {
    handleFileUpload() {
      this.$emit("handleUpload", this.$refs.imageFile.files[0]);
      if (this.$refs.imageFile.files[0].name) {
        this.nameFile = this.$refs.imageFile.files[0].name;
      } else this.nameFile = "";
    },
  },
};
</script>

<style lang="css" scoped>
.input-img {
  width: 100%;
  display: flex;
  gap: 10px;
  /* align-items: center; */
}
.input-img__fake-btn {
  width: 32px;
  height: 27px;
  /* height: 100%; */
  /* min-height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-yellow);
  cursor: pointer;
}

.input-img__btn {
  display: none;
}
</style>
