<template>
  <div class="input-combobox">
    <v-autocomplete
      dense
      hide-details
      hide-no-data
      outlined
      append-icon=""
      class="base-auto-complete"
      :items="dataSelect"
      v-model="selectedValue"
      :item-text="getItemText"
      item-value="id"
    >
    </v-autocomplete>
    <a
      v-if="selectedValue"
      class="input-combobox__button"
      @click="handeClickRedirect"
      style="font-family: 'Inter'; font-size: 20px"
    >
      <span>
        <svg
          width="15"
          height="13"
          viewBox="0 0 15 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.58782 13L7.49407 11.9205L11.9969 7.41761H0.334977V5.85511H11.9969L7.49407 1.36648L8.58782 0.272727L14.9515 6.63636L8.58782 13Z"
            fill="black"
          />
        </svg>
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: "InputComboBox",
  props: {
    dataSelect: {},
    value: {},
  },
  data: () => ({}),
  methods: {
    handeClickRedirect() {
      window.open(`Devices/${this.selectedValue}`, "");
    },
    getItemText(item) {
      return `${item.name} ${item.serial ? item.serial : ""}`;
    },
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.input-combobox {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 1em;
}

/* Arrow button */
.input-combobox__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  /* height: 100%; */
  background: var(--color-yellow);

  text-decoration: none;
  color: inherit;
}
</style>
