<template>
  <!-- <div class="canvas-box__body"> -->
  <!-- :style="{ width: '100%', height: '100%' }" -->
  <div class="canvas-box__wrapper" ref="canvasBoxWrapper">
    <canvas
      class="canvas-box"
      ref="canvasPolygon"
      @mousemove="getPositionMouse($event)"
      @click.left="clickMouse"
      @keyup.esc="clearEvent"
      @click.right.prevent="clearEvent"
    ></canvas>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "DrawSpace",
  props: {
    width: {
      type: Number,
      require: true,
    },
    height: {
      type: Number,
      require: true,
    },
    editSpace: Boolean,
  },
  data() {
    return {
      x: 0,
      y: 0,
      ctx: null,
      polygon: {
        polygonVertices: [],
        devices: Array,
      },
      mode: {
        create: false,
        edit: false,
      },
      isShow: false,

      lineWidth: 3,
      radiusClick: 10,
    };
  },
  methods: {
    getPositionMouse(event) {
      this.x = event.offsetX;
      this.y = event.offsetY;

      if (this.mode.create) {
        this.drawLifeLine(this.x, this.y);
      }
    },
    /**
     *event on left mouse click
     */
    clickMouse() {
      this.mode.create ? false : true;

      if (this.checkClosure()) {
        this.mode.create = false;
        this.saveNewSpace(() => {
          this.$emit("onCreatedSpace");
        });
        return;
      }

      this.putDot(this.x, this.y);
      this.mode.create = true;
      this.polygon.polygonVertices.push({ x: this.x, y: this.y });
    },
    /**
     * Add new space to store
     * @params {function} callback - function
     */
    saveNewSpace(callback) {
      //   this.$store.commit("updateArrayPolygons", this.polygon);
      this.isShow = false;
      this.$store.commit("updatePolygon", this.polygon.polygonVertices);
      this.polygon.polygonVertices = new Array();
      this.clearEvent();
      return callback();
    },
    /**
     * Auto close polygon with first point
     */
    checkClosure() {
      if (
        this.polygon.polygonVertices.length > 0 &&
        this.x >= this.polygon.polygonVertices[0].x - this.radiusClick &&
        this.x <= this.polygon.polygonVertices[0].x + this.radiusClick &&
        this.y >= this.polygon.polygonVertices[0].y - this.radiusClick &&
        this.y <= this.polygon.polygonVertices[0].y + this.radiusClick
      ) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Clear data and canvas (now using by press rmb)
     */
    clearEvent() {
      this.ctx.clearRect(0, 0, this.width, this.height);
      this.mode.create = this.mode.edit = false;
      this.polygon.polygonVertices = [];
      this.isShow = false;
    },
    /**
     * Draw new point (tmp function)
     * may-be need to component for new point
     */
    putDot(pos_x, pos_y) {
      this.ctx.beginPath();
      this.ctx.arc(pos_x, pos_y, 5, 0, 2 * Math.PI);
      this.ctx.stroke();
    },
    /**
     * ReDraw all polygons for display life line
     */
    drawLifeLine(pos_x, pos_y) {
      console.log("draw life line");
      this.ctx.clearRect(0, 0, this.width, this.height);
      this.drawPolygon(this.polygon);
      this.ctx.beginPath();
      this.ctx.moveTo(
        this.polygon.polygonVertices[this.polygon.polygonVertices.length - 1].x,
        this.polygon.polygonVertices[this.polygon.polygonVertices.length - 1].y
      );
      this.checkClosure()
        ? this.ctx.lineTo(
            this.polygon.polygonVertices[0].x,
            this.polygon.polygonVertices[0].y
          )
        : this.ctx.lineTo(pos_x, pos_y);

      this.ctx.stroke();
    },
    /**
     * ReDraw points (verticalse)
     */
    drawPolygon(space) {
      space.polygonVertices.forEach((element) => {
        this.putDot(element.x, element.y);
      });
      if (space.polygonVertices.length >= 2) {
        for (let i = 0; i < space.polygonVertices.length - 1; i++) {
          this.ctx.beginPath();
          this.ctx.moveTo(
            space.polygonVertices[i].x,
            space.polygonVertices[i].y
          );
          this.ctx.lineTo(
            space.polygonVertices[i + 1].x,
            space.polygonVertices[i + 1].y
          );
          this.ctx.stroke();
        }
      }
    },

    /**
     * Drawing saved space
     * @params {space} space - object of space { with array dont at
     * example
     * space.polygonVertices = [
     *  {
     *    x,y
     *  }
     * ]
     *
     *  }
     */
    drawSpace(space) {
      this.ctx.beginPath();
      this.ctx.moveTo(space.polygonVertices[0].x, space.polygonVertices[0].y);

      space.polygonVertices.forEach((element) => {
        this.ctx.lineTo(element.x, element.y);
      });
      this.ctx.lineTo(space.polygonVertices[0].x, space.polygonVertices[0].y);
      this.ctx.fillStyle = "#5FB760";
      this.ctx.closePath();
      this.ctx.fill();
      this.ctx.stroke();
    },
  },

  mounted() {
    const rect = this.$refs.canvasPolygon;
    const canvasWrapper = this.$refs.canvasBoxWrapper;

    canvasWrapper.width = this.width;
    canvasWrapper.height = this.height;
    rect.width = this.width;
    rect.height = this.height;

    this.ctx = this.$refs.canvasPolygon.getContext("2d");
    this.ctx.lineWidth = this.lineWidth;
    this.isShow = this.editSpace;
  },
  computed: {
    polygons() {
      return this.$store.getters.getPolygons;
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-box__wrapper {
  border: 1px solid black;
  margin: 0 auto;
}

.canvas-box {
  z-index: 99;
}
/* 
.canvas-box__body {
  margin: 0 auto;
  max-width: 1200px;
} */
</style>
