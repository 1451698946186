<template>
  <v-dialog max-width="600" v-model="isShowSchedule">
    <v-card class="" style="">
      <v-card-title class="pa-2">
        <v-icon @click="prevDate"> mdi-chevron-left</v-icon>
        <v-menu ref="menu" v-model="menu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              hide-details
              outlined
              class="base-text-field"
              style="max-width: 120px"
              readonly
              v-model="date"
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            no-title
            scrollable
            v-model="date"
            @input="handeChangeDate"
          />
        </v-menu>
        <v-icon>mdi-calendar</v-icon>
        <v-icon @click="nextDate"> mdi-chevron-right</v-icon>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="loader mt-5" v-if="isLoading">
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </div>
        <div v-if="!isLoading" class="mt-5">
          {{ listBooking.length === 0 ? "Данных нет" : "" }}
        </div>
        <v-row
          v-for="(item, key) in listBooking"
          :key="key"
          no-gutters
          style="border-bottom: 1px solid #e9e9e9"
          class="pa-1 ma-1"
        >
          <v-col cols="2" style="min-width: 110px">
            {{ item.time_begin.slice(0, 5) }} - {{ item.time_end.slice(0, 5) }}
          </v-col>
          <v-col> {{ item.description }} </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ScheduleModal",
  props: {
    value: {},
    spaceId: {
      type: String,
    },
  },

  data: () => ({
    date: null,
    menu: false,
    isLoading: false,
  }),
  mounted() {
    this.date = new Date(Date.now()).toISOString().substr(0, 10);
    this.isLoading = true;
    this.$store
      .dispatch("fetchSpaceBooking", {
        id: this.spaceId,
        date: this.date,
      })
      .then(() => {
        this.isLoading = false;
      });
  },
  beforeDestroy() {
    this.$store.commit("SET_LIST_BOOKING", []);
  },
  computed: {
    listBooking() {
      return this.$store.getters.getListBooking;
    },
    isShowSchedule: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    prevDate() {
      let tmp = new Date(this.date);
      tmp.setDate(new Date(this.date).getDate() - 1);
      this.date = new Date(tmp).toISOString().substr(0, 10);
      this.handeChangeDate();
    },
    nextDate() {
      let tmp = new Date(this.date);
      tmp.setDate(new Date(this.date).getDate() + 1);
      this.date = new Date(tmp).toISOString().substr(0, 10);
      this.handeChangeDate();
    },
    handeChangeDate() {
      this.menu = false;
      this.isLoading = true;
      this.$store
        .dispatch("fetchSpaceBooking", {
          id: this.spaceId,
          date: this.date,
        })
        .then(() => (this.isLoading = false));
    },
  },
};
</script>
