export const Week = [
  {
    name: "Понедельник",
    key: "MONDAY",
  },
  {
    name: "Вторник",
    key: "TUESDAY",
  },
  {
    name: "Среда",
    key: "WEDNESDAY",
  },
  {
    name: "Четверг",
    key: "THURSDAY",
  },
  {
    name: "Пятница",
    key: "FRIDAY",
  },
  {
    name: "Суббота",
    key: "SATURDAY",
  },
  {
    name: "Воскресенье",
    key: "SUNDAY",
  },
];

/**
 * Method to transfrom object work_time from server to array of String work_time
 * @param {*} work_time
 * @returns
 */
const workTimeObjectToArray = (work_time) => {
  let new_arr = new Array(7);
  for (const [key, value] of Object.entries(work_time)) {
    const days = {
      MONDAY: 0,
      TUESDAY: 1,
      WEDNESDAY: 2,
      THURSDAY: 3,
      FRIDAY: 4,
      SATURDAY: 5,
      SUNDAY: 6,
    };
    new_arr[days[key]] = value
      ? String(value.begin.slice(0, 5) + "-" + value.end.slice(0, 5))
      : "";
  }

  return new_arr.map(
    (item, idx) =>
      (item = { name: Week[idx].name, key: Week[idx].key, value: item })
  );
};

export default workTimeObjectToArray;
