<template>
  <v-dialog v-model="isActive" :width="830" persistent>
    <form-placement-layout
      title="здания"
      :action="action"
      @submit="submitForm"
      @cancel="isActive = false"
    >
      <v-form>
        <error-info :error="'name' in errorsValidated">
          {{ errorsValidated.name }}
        </error-info>
        <div class="d-flex align-center mb-4">
          <span class="form__item-text text-left">
            Наименование
          </span>
          <v-text-field
            dense
            hide-details
            outlined
            autofocus
            v-model="formData.name"
            class="base-text-field"
          />
        </div>
        <div class="d-flex align-center mb-4">
          <span class="form__item-text text-left">
            Описание
          </span>
          <v-text-field
            dense
            hide-details
            outlined
            v-model="formData.description"
            class="base-text-field"
          />
        </div>

        <error-info :error="'address' in errorsValidated">
          {{ errorsValidated.address }}
        </error-info>

        <div class="d-flex align-center mb-4">
          <span class="form__item-text text-left">
            Адрес
          </span>
          <v-text-field
            dense
            hide-details
            outlined
            v-model="formData.address"
            class="base-text-field"
          />
        </div>
        <!-- <div class="form__item d-flex align-center">
          <span class="form__item-text text-left dialog-window__font"
            >Часовой пояс</span
          >
          <v-select
            :items="timeZones"
            v-model="formData.timezone"
            item-text="name"
            item-value="value"
            class="form__input-timeZone dialog-window__text-field"
            style="max-width: 108px; font-size: 12px"
            dense
            append-icon=""
          />
        </div> -->
        <error-info :error="'work_time' in errorsValidated">
          {{ errorsValidated.work_time }}
        </error-info>
        <div class="form__item d-flex align-center mb-4">
          <span class="form__item-text text-left">Рабочее время</span>
          <work-time v-model="formData.work_time" />
        </div>
        <!-- <div class="form__item d-flex align-center mb-5">
          <span class="form__item-text text-left dialog-window__font"
            >Изображение</span
          >
          <input-img
            v-model="formData.image"
            @handleUpload="
              (value) => {
                formData.image = value;
              }
            "
          />
        </div> -->
      </v-form>
    </form-placement-layout>
  </v-dialog>
</template>

<script>
import FormPlacementLayout from "./FormPlacementLayout.vue";
import ErrorInfo from "../ErrorInfo.vue";
import WorkTime from "./DialogAddEditTime.vue";
// import InputImg from "./fields/DialogAddEditInputImg.vue";

import formPlacement from "@/mixins/formPlacement.js";

import convertTime from "@/utils/workTimeObjectToArray.js";
import { convertArray } from "@/utils/workTimeArrayToObject.js";

import { mapActions } from "vuex";
export default {
  name: "Formbilding",
  components: {
    FormPlacementLayout,
    ErrorInfo,
    WorkTime,
    // InputImg,
  },
  mixins: [formPlacement],
  data() {
    return {
      formData: {
        work_time: [],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      add: "createBuilding",
      edit: "updateBuilding",
    }),
    init() {
      this.formData.work_time = convertTime(
        this.$store.getters.getActiveNode[0].work_time
      );

      if (this.data) {
        this.formData = { ...this.data };
        this.formData.work_time = convertTime(this.formData.work_time);
      }
    },
    submitForm() {
      if (!this.validate()) return;

      const payload = {
        name: this.formData.name,
        description: this.formData.description,
        address: this.formData.address,
        work_time: convertArray(this.formData.work_time),
        // timezone: this.formData.timezone,
        // img
        ...(this.action === "edit" ? "" : { parent_id: this.parentId }),
      };

      if (this.action === "add") {
        this.add(payload)
          .then((response) => {
            this.$emit("onSubmitForm", response.data.data);
            this.isActive = false;
          })
          .catch((err) => {
            alert(err);
          });
      }
      if (this.action === "edit") {
        this.edit({
          id: this.formData.id,
          payload: payload,
        })
          .then((response) => {
            this.$emit("onSubmitForm", response.data.data);
            this.isActive = false;
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    validate() {
      this.errorsValidated = {};
      const regName = /^.{3,127}$/;
      if (!regName.test(this.formData.name) || !this.formData.name) {
        this.errorsValidated.name =
          "Наименование должно быть от 3 до 127 символов";
      }

      if (!this.formData.address || this.formData.address.length < 3) {
        this.errorsValidated.address = "Заполните адрес";
      }

      const regTime = /[0-9]{2}:[0-9]{2}-[0-9]{2}:[0-9]{2}$/;
      for (let item of this.formData.work_time) {
        if (!regTime.test(item.value) && item.value !== "") {
          this.errorsValidated.work_time = "Неверно введен формат времени";
        }
      }

      if (Object.keys(this.errorsValidated).length == 0) return true;
      return false;
    },
  },
  computed: {
    timeZones() {
      return this.$store.getters.getTimeZone;
    },
  },
};
</script>
