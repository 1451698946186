<template>
<div>
  <v-dialog
      width="400px"
      :value="isInfoDialog"
      @input="$emit('accept')"
      v-if="isInfoDialog"
  >
    <v-card tile>
      <v-card-title>
        Внимание
      </v-card-title>
      <v-card-text>
        {{ infoDialogText }}
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn class="text-capitalize" color="#5FB760" elevation="0"
               @click="$emit('accept')"
               height="29" style="font-size: 12px" tile>
          ОК
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
export default {
  name: "InfoDialog",
  props: {
    isInfoDialog: {
      type: Boolean,
      default: false
    },
    infoDialogText: {
      type: String,
      default: "Выполнено успешно"
    },
  }
}
</script>

<style scoped>

</style>
