<template>
  <div v-if="true">
    <div v-if="isToolTip" class="tool-tip" :style="`top: ${y}px;left: ${x}px;`">
      {{ itemName }}
    </div>
    <v-data-table
      :headers="[{ text: 'План' }]"
      dense
      fixed-header
      hide-default-footer
      hide-default-header
      no-data-text="Нет данных"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th style="background: #f8f8f8">
              <span class="scheme-main__title-font">План</span>
            </th>
          </tr>
        </thead>
      </template>

      <!-- overflow: hidden -->
      <template v-slot:body>
        <div id="hello" ref="hello" style="position: relative; ">
          <draw-space
            ref="refDrawSpace"
            v-if="isCreateSpace"
            :width="widthImage"
            :height="heightImage"
            style="
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              z-index: 10;
            "
            @onCreatedSpace="handleCreatedSpace"
          ></draw-space>
          <v-progress-circular v-if="isLoading" indeterminate color="primary" />
          <img ref="imageRef" :src="$store.getters.getSchemaImage" alt="" />
          <div
            ref="wrapper"
            style="
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              z-index: 0;
            "
          >
            <canvas @mousemove="getMousePosition" ref="refCanvas"></canvas>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <scheme-table-space
          v-model="value"
          v-if="isShowTable"
          :isAllowCreateSpace="isAllowCreateSpace"
          @onClickAdd="onAddDevice"
          @onClickRow="clickRow"
          @onDeleteSpace="handleDeleteSpace"
          @onConnetingSpace="handleEditSpace"
          @onClickShowSchedule="handleClickShowSchedule"
        />
      </template>
    </v-data-table>
    <schedule-modal
      v-if="isShowSchedule"
      v-model="isShowSchedule"
      :spaceId="spaceId"
    />
    <info-dialog-promise
      ref="infoDialog"
      :textInfo="textInfo"
    ></info-dialog-promise>
  </div>
</template>

<script>
import DrawSpace from "@/components/DrawSpace/DrawSpace.vue";
import SchemeTableSpace from "@/components/Scheme/SchemeTableSpace.vue";
import ScheduleModal from "@/components/Scheme/ScheduleModal.vue";
import InfoDialogPromise from "@/components/Dialogs/InfoDialogPromise.vue";

export default {
  name: "SchemeMain",
  components: {
    DrawSpace,
    SchemeTableSpace,
    ScheduleModal,
    InfoDialogPromise,
  },
  props: [
    "clickOnSpaceTree",
    "value",
    "currentActive",
    "onAddSpace",
    "updatedSpace",
  ],
  emits: ["onSubmitForm", "onAddDevice"],

  data: () => ({
    isLoading: false,

    x: 0,
    y: 0,
    pathsSpaces: [],
    arraySpaces: [],

    isToolTip: false,
    isCreateSpace: false,
    isShowSpace: false,

    ctx: null,
    widthImage: 0,
    heightImage: 0,
    isEditSpace: false,
    tmpSpaceData: null,
    isShowSchedule: false,
    spaceId: null,
    itemName: "",
    isDeleted: false,
    isNewSpace: false,
    textInfo: "",
  }),
  mounted() {
    this.ctx = this.$refs.refCanvas.getContext("2d");
    if (this.currentActive.type === "space") this.initDraw(this.currentActive);
    else {
      if (this.value) this.initDraw(this.value);
    }
  },
  methods: {
    handleClickShowSchedule(item) {
      this.isShowSchedule = true;
      this.spaceId = item.id;
    },
    clickRow(item) {
      if (this.isEditSpace === false) this.clearCanvas();

      if (!item.coordinates) return;
      else {
        this.drawSpace(item);
      }
    },
    handleEditSpace(item) {
      this.isCreateSpace = !this.isCreateSpace;
      // this.isEditSpace = !this.isEditSpace;
      this.tmpSpaceData = item;
      if (this.isCreateSpace) this.initDraw(this.value);
      else this.clearCanvas();
    },
    handleCreatedSpace() {
      this.isCreateSpace = false;

      if (this.isNewSpace) {
        this.isNewSpace = false;
        this.$emit("onAddSpace", { type: "space" });
        return;
      }

      this.$store
        .dispatch("updateSpace", {
          id: this.tmpSpaceData.id,
          // name: this.tmpSpaceData.name,
          payload: {
            dots: this.$store.getters.getPolygon.polygonVertices,
          },
        })
        .then(() => {
          this.$store.dispatch("fetchTree");
          this.$store.dispatch("fetchSchema", this.value.id).then(() => {
            this.drawAllSpace(this.value.children);
          });
        })
        .finally(() => {
          this.$store.commit("updatePolygon", []);
        });
    },
    getHeightBlock() {
      return this.$refs.hello.clientHeight;
    },
    getWidthBlock() {
      return this.$refs.hello.clientWidth;
    },
    onSbmitForm(openInfo) {
      this.$emit("onSubmitForm", { openInfo });
    },
    onAddDevice() {
      this.isCreateSpace = !this.isCreateSpace;
      this.isEditSpace = false;
      this.isNewSpace = true;
      if (this.isCreateSpace === true) this.drawAllSpace(this.value.children);
      else this.clearCanvas();
    },

    // Отвязка нарисованного пространства от схема
    // Нужен рефакторинг для имен
    async isConfirmDialog(item) {
      this.textInfo = `Вы уверены что хотите отвязать ${item.name}?`;
      let isAccept = null;
      await this.$refs.infoDialog
        .open()
        .then((res) => {
          isAccept = res;
        })
        .catch((res) => {
          isAccept = res;
        });
      return isAccept;
    },

    async handleDeleteSpace(item) {
      this.isDeleted = true;

      if (!(await this.isConfirmDialog(item))) return;
      this.$store
        .dispatch("updateSpace", {
          id: item.id,
          payload: {
            dots: [],
          },
        })
        .then(() => {
          this.$store.dispatch("fetchTree");
          this.$store.dispatch("fetchSchema", this.value.id).then(() => {
            this.drawAllSpace(this.value.children);
          });
        })
        .finally(() => {
          this.$store.commit("updatePolygon", []);
        });
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.widthImage, this.heightImage);
    },
    drawAllSpace(spaces) {
      this.$store.commit("setSpacesArray", spaces);
      this.ctx.clearRect(0, 0, this.widthImage, this.heightImage);
      this.isShowSpace = true;
      spaces.forEach((element) => {
        if (!("coordinates" in element) || !element.coordinates) return;
        let maxX = element.coordinates[0].x;
        let maxY = element.coordinates[0].y;

        let minY = element.coordinates[0].y;

        if (element.coordinates.length <= 1) return;

        let path = new Path2D();

        path.moveTo(element.coordinates[0].x, element.coordinates[0].y);
        element.coordinates.forEach((element) => {
          if (element.x < maxX) maxX = element.x;
          if (element.y > maxY) maxY = element.y;

          if (element.y < minY) minY = element.y;

          path.lineTo(element.x, element.y);
        });

        path.lineTo(element.coordinates[0].x, element.coordinates[0].y);

        this.ctx.fillStyle = "rgba(95, 183, 96, 0.3)";
        path.closePath();
        this.ctx.fill(path);
        this.ctx.stroke(path);
        this.drawSpaceName(maxX + 10, maxY - (maxY - minY) / 2, element.name);

        this.pathsSpaces.push(path);
        this.arraySpaces.push({ path: path, name: element.name });
      });
    },
    drawSpace(space) {
      if (!space.coordinates) {
        this.ctx.clearRect(0, 0, this.widthImage, this.heightImage);
        return;
      }

      let maxX = space.coordinates[0].x;
      let maxY = space.coordinates[0].y;
      let minY = space.coordinates[0].y;
      this.ctx.clearRect(0, 0, this.widthImage, this.heightImage);
      if (space.coordinates.length <= 1) return;
      this.isShowSpace = true;
      let path = new Path2D();

      path.moveTo(space.coordinates[0].x, space.coordinates[0].y);
      space.coordinates.forEach((element) => {
        if (element.x < maxX) maxX = element.x;
        if (element.y > maxY) maxY = element.y;

        if (element.y < minY) minY = element.y;
        path.lineTo(element.x, element.y);
      });
      path.lineTo(space.coordinates[0].x, space.coordinates[0].y);
      this.ctx.fillStyle = "rgba(95, 183, 96, 0.3)";
      path.closePath();
      this.ctx.fill(path);
      this.ctx.stroke(path);
      this.pathsSpaces = [];
      this.pathsSpaces.push(path);
      this.drawSpaceName(maxX + 10, maxY - (maxY - minY) / 2, space.name);
    },
    getMousePosition(event) {
      let x = event.offsetX;
      let y = event.offsetY;
      let bound = this.$refs.refCanvas.getBoundingClientRect();

      for (let i = 0; i < this.arraySpaces.length; i++) {
        if (this.ctx.isPointInPath(this.arraySpaces[i].path, x, y)) {
          this.isToolTip = true;
          this.itemName = this.arraySpaces[i].name;

          this.x = event.pageX;
          this.y = event.pageY - bound.top + 60;
          break;
        } else {
          this.isToolTip = false;
        }
      }
    },
    /**
     * draw label with name of space
     * @params x, y name
     */
    drawSpaceName(x, y, name) {
      this.ctx.font = "16px Inter";
      let lineH = 16 * 1.286;
      let textW = this.ctx.measureText(name).width + 2;

      this.ctx.fillStyle = "#ffffff";
      this.ctx.fillRect(x, y - lineH / 2 + 1, textW + 4, lineH);
      this.ctx.strokeRect(x, y - lineH / 2 + 1, textW + 4, lineH);
      this.ctx.fillStyle = "#000000";
      this.ctx.fillText(name, x + 4, y + 6);
    },
    initDraw(node) {
      if (Array.isArray(node)) node = node[0];
      let img = new Image();
      img.src = this.$store.getters.getSchemaImage;
      img.onload = () => {
        const rect = this.$refs.refCanvas;
        const wrapper = this.$refs.imageRef;
        rect.width = wrapper.offsetWidth;
        rect.height = wrapper.offsetHeight;
        this.widthImage = wrapper.offsetWidth;
        this.heightImage = wrapper.offsetHeight;

        rect.style.width = wrapper.offsetWidth;
        rect.style.height = wrapper.offsetHeight;

        if (node.type === "schema") {
          this.drawAllSpace(node.children);
        }
        if (node.type === "space") {
          this.drawAllSpace([node]);
        }
      };
    },
  },
  computed: {
    isShowTable() {
      if (!this.$store.getters.getActiveNode[0]) return;
      if (
        "children" in this.$store.getters.getActiveNode[0] &&
        this.$store.getters.getActiveNode[0].children.length > 0
      ) {
        if (this.$store.getters.getActiveNode[0].children[0].type === "schema")
          return false;
      }
      return true;
    },
    isAllowCreateSpace() {
      return !!this.$store.getters.getSchemaImage && !this.isLoading;
    },
  },
  watch: {
    // ??
    clickOnSpaceTree(newVal) {
      this.drawSpace(newVal);
    },
    value(newVal, oldVal) {
      if (!this.value) return;

      if (this.isDeleted) {
        this.initDraw(this.value);
        this.isDeleted = false;
        return;
      }
      // this.initDraw(this.currentActive);

      if (this.value.id in this.$store.getters.getHashedImages) {
        this.$store.commit(
          "setSchemaImage",
          this.$store.getters.getHashedImages[this.value.id]
        );
      } else {
        if (!oldVal || newVal.id !== oldVal.id) {
          this.isLoading = true;
          this.$store
            .dispatch("fetchSchemaFile", this.value.id)
            .then(() => {
              this.initDraw(this.currentActive);
            })
            .finally(() => (this.isLoading = false));
        }
      }
    },
    currentActive(newVal) {
      if (newVal) {
        this.initDraw(newVal);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scheme-main__title-font {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: calc(15 / 12 * 100%);
  color: #000;
}

.v-data-table-header {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  line-height: calc(15 / 12 * 100%);
  color: #000;
}

.tool-tip {
  position: absolute;
  z-index: 2;
  border-radius: 5px;
  background: rgba(gray, 0.9);
  color: #fff;
  opacity: 0.9;
  font-size: 12px;
  padding: 5px 16px;
}
</style>
