<template>
  <div
    v-if="true"
    class="scheme-main__spaces spaces mt-2"
    style="margin-bottom: 5%"
  >
    <div
      class="
        scheme-main__title-font
        d-flex
        align-center
        justify-space-between
        mb-2
      "
    >
      <span class="black--text" style="font-weight: 700;">Пространства</span>
      <v-btn
        color="green"
        tile
        elevation="0"
        height="28"
        :disabled="!isAllowCreateSpace"
        @click="onClickAdd"
        min-width="40px"
        max-width="40px"
      >
        <span style="font-size: 20px">+</span>
      </v-btn>
    </div>
    <v-data-table
      @click:row="onClickRow"
      class="base-table"
      dense
      hide-default-header
      hide-default-footer
      :headers="headers"
      :items="value.children"
    >
      <template v-slot:item.schedule="{ item }">
        <v-icon @click="onShowShedule(item)"> mdi-tablet-dashboard </v-icon>
      </template>
      <template
        v-slot:item.scheme="{ item }"
        class="justify-center"
        style="max-width: 80px; width: fit-content"
      >
        <v-icon
          v-if="item.coordinates ? false : true"
          @click="onConnetingSpace(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:item.delete="{ item }" style="max-width: 80px">
        <v-icon v-if="item.coordinates ? true : false" @click="onDelete(item)">
          mdi-close
        </v-icon>
      </template>

      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th
              v-for="(h, hIndex) in headers"
              :key="'header - ' + hIndex"
              class="text-left"
            >
              <span class="scheme-main__title-font">{{ h.text }}</span>
            </th>
          </tr>
        </thead>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "ShemeMain",
  props: {
    value: {
      default() {
        return { children: [] };
      },
    },
    isAllowCreateSpace: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prevRow: null,
      confirmationDialogText: "",
      isConfirmationDialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Наименование",
          value: "name",
          width: "245px",
        },
        {
          text: "Вместимость",
          value: "capacity",
          width: "110px",
        },
        {
          text: "Описание",
          value: "description",
          width: "460px",
        },
        {
          text: "Расписание",
          value: "schedule",
          width: "70px",
        },
        {
          text: "Привязать к схеме ",
          value: "scheme",
          width: "90px",
          class: "d-flex justify-center",
        },
        {
          value: "delete",
          text: "Отвязать",
          width: "80px",
        },
      ],
    };
  },
  computed: {},
  methods: {
    onConnetingSpace(item) {
      this.$emit("onConnetingSpace", item);
    },
    onClickAdd() {
      if (this.prevRow !== null) this.prevRow.select(false);
      this.$emit("onClickAdd");
    },
    onDelete(item) {
      this.$emit("onDeleteSpace", item);
    },
    onClickRow(value, row) {
      if (this.prevRow !== null) this.prevRow.select(false);
      row.select(true);
      this.prevRow = row;
      this.$emit("onClickRow", value);
    },
    onShowShedule(item) {
      this.$emit("onClickShowSchedule", item);
    },
  },
};
</script>
