<template>
  <v-dialog v-model="isActive" :width="setWidth" persistent>
    <form-placement-layout
      @submit="submitForm"
      @cancel="isActive = false"
      title="региона"
      :action="action"
    >
      <v-form class="dialog-window__form form">
        <error-info :error="'name' in errorsValidated">
          {{ errorsValidated.name }}
        </error-info>

        <div class="d-flex align-center mb-4">
          <span class="form__item-text text-left">
            Наименование
          </span>
          <v-text-field
            dense
            hide-details
            full-width
            outlined
            autofocus
            v-model="formData.name"
            class="base-text-field"
          />
        </div>
        <div class="d-flex align-center mb-4">
          <span class="form__item-text text-left dialog-window__font">
            Описание
          </span>
          <v-text-field
            dense
            hide-details
            full-width
            outlined
            v-model="formData.description"
            class="base-text-field"
          />
        </div>

        <error-info :error="'timezone' in errorsValidated">
          {{ errorsValidated.timezone }}
        </error-info>
        <div class="d-flex align-center mb-4">
          <span class="form__item-text text-left dialog-window__font"
            >Часовой пояс</span
          >
          <v-select
            dense
            hide-details
            outlined
            width=""
            append-icon=""
            :items="timeZones"
            v-model="formData.timezone"
            item-text="name"
            item-value="value"
            class="base-select"
            style=""
          />
        </div>

        <error-info :error="'work_time' in errorsValidated">
          {{ errorsValidated.work_time }}
        </error-info>

        <div class="d-flex align-center mb-4">
          <span class="form__item-text text-left dialog-window__font"
            >Рабочее время</span
          >
          <work-time v-model="formData.work_time" />
        </div>
      </v-form>
    </form-placement-layout>
  </v-dialog>
</template>

<script>
import FormPlacementLayout from "./FormPlacementLayout.vue";
import ErrorInfo from "../ErrorInfo.vue";
import WorkTime from "./DialogAddEditTime.vue";

import formPlacement from "@/mixins/formPlacement.js";

import convertTime, { Week } from "@/utils/workTimeObjectToArray.js";
import { convertArray } from "@/utils/workTimeArrayToObject.js";

import { mapActions } from "vuex";

export default {
  name: "FormRegion",
  components: { ErrorInfo, FormPlacementLayout, WorkTime },
  mixins: [formPlacement],
  props: {},
  data() {
    return {
      formData: {
        work_time: [],
      },
      title: "региона",
      setWidth: 830,
      Week: Week,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    timeZones() {
      return this.$store.getters.getTimeZone;
    },
  },
  methods: {
    ...mapActions({
      add: "createRegion",
      edit: "updateRegion",
    }),
    init() {
      this.formData.work_time = this.Week.map((item) => {
        return { name: item.name, key: item.key, value: "08:00-20:00" };
      });

      if (this.parentId) {
        if (!this.data) {
          this.formData.work_time = convertTime(this.parentInfo.work_time);
          this.formData.timezone = this.parentInfo.timezone;
        }
      }
      if (this.data) {
        this.formData = { ...this.data };
        this.formData.work_time = convertTime(this.formData.work_time);
      }
    },
    submitForm() {
      if (!this.validate()) return;
      const payload = {
        name: this.formData.name,
        description: this.formData.description,
        timezone: this.formData.timezone,
        work_time: convertArray(this.formData.work_time),
        ...(this.action === "add" && this.parentId
          ? { parent_id: this.parentId }
          : ""),
      };

      if (this.action === "add") {
        this.add(payload).then((response) => {
          this.$emit("onSubmitForm", response.data.data);
          this.isActive = false;
        });
      } else if (this.action === "edit") {
        this.edit({
          id: this.formData.id,
          payload: payload,
        }).then((response) => {
          this.$emit("onSubmitForm", response.data.data);
          this.isActive = false;
        });
      }
    },
    validate() {
      this.errorsValidated = {};
      const regName = /^.{3,127}$/;
      if (!regName.test(this.formData.name) || !this.formData.name) {
        this.errorsValidated.name =
          "Наименование должно быть от 3 до 127 символов";
      }
      if (!this.formData.timezone) {
        this.errorsValidated.timezone = "Добавьте часовой пояс";
      }

      const regTime = /[0-9]{2}:[0-9]{2}-[0-9]{2}:[0-9]{2}$/;
      for (let item of this.formData.work_time) {
        if (!regTime.test(item.value) && item.value !== "") {
          this.errorsValidated.work_time = "Неверно введен формат времени";
        }
      }

      if (Object.keys(this.errorsValidated).length == 0) return true;
      return false;
    },
  },
};
</script>
