<template>
  <div @mouseup="endDragging" class="wrapper">
    <div
      class="left-window"
      :style="{
        width: `${dividerPosition}%`,
      }"
    >
      <slot name="left"></slot>
    </div>

    <div
      @mousedown="startDragging"
      class="divider"
      :style="{
        left: `${dividerPosition}%`,
      }"
    />
    <div
      class="right-window"
      :style="{
        width: `${100 - dividerPosition}%`,
      }"
    >
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResizableMenu",
  data: () => ({
    dividerPosition: 30,
  }),
  methods: {
    handleDragging(e) {
      const percentage = (e.pageX / window.innerWidth) * 100;
      if (percentage >= 10 && percentage <= 90)
        this.dividerPosition = percentage.toFixed(2);
    },
    startDragging() {
      document.addEventListener("mousemove", this.handleDragging);
    },
    endDragging() {
      document.removeEventListener("mousemove", this.handleDragging);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  display: flex;
  min-width: 100%;
}

.divider {
  height: 100%;
  width: 1px;
  background: #fff;
  border: 1px solid #e9e9e9;
  transform: translate(-3px);
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: ew-resize;
}
</style>
