/**
 * convert Array work_time to Object
 */
export const convertArray = (array) => {
  return array.reduce((acc, item) => {
    acc = {
      ...acc,
      [item.key]: item.value.trim()
        ? {
            begin: item.value.slice(0, 5),
            end: item.value.slice(-5),
          }
        : null,
    };
    return acc;
  }, {});
};
