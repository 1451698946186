//list for weak days
<template>
  <div class="d-flex flex-wrap" style="gap: 16px">
    <div v-for="(item, idx) in dataTimeWork" :key="idx">
      <div>
        {{ item.name }}
      </div>
      <div>
        <v-text-field
          dense
          hide-details
          outlined
          :disabled="!isActive"
          v-model="item.value"
          style="max-width: 82px"
          class="base-text-field"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkTime",
  props: {
    value: {
      type: Array,
      require: true,
    },
    isActive: {
      type: Boolean,
      require: false,
      default: true,
    },
  },

  data: () => ({}),
  computed: {
    dataTimeWork: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value.trim());
      },
    },
  },
};
</script>
