<template>
  <v-dialog v-model="isActive" :width="830" class="dialog" persistent>
    <form-placement-layout
      title="Пространства"
      :action="action"
      @submit="submitForm"
      @cancel="isActive = false"
    >
      <error-info :error="'name' in errorsValidated">
        {{ errorsValidated.name }}
      </error-info>
      <div class="d-flex align-center mb-4">
        <span class="form__item-text text-left ">
          Наименование
        </span>
        <v-text-field
          dense
          hide-details
          outlined
          autofocus
          v-model="formData.name"
          class="base-text-field"
        />
      </div>

      <error-info :error="'capacity' in errorsValidated">
        {{ errorsValidated.capacity }}
      </error-info>
      <div class="d-flex align-center mb-4">
        <span class="form__item-text text-left">
          Вместимость
        </span>
        <v-text-field
          dense
          hide-details
          outlined
          v-model="formData.capacity"
          class="base-text-field"
        />
      </div>

      <div class="form__item d-flex align-center mb-4">
        <span class="form__item-text text-left">
          Описание
        </span>
        <v-text-field
          dense
          hide-details
          outlined
          v-model="formData.description"
          class="base-text-field"
        />
      </div>

      <div class="d-flex align-center mb-4">
        <span class="form__item-text text-left">Профиль</span>
        <v-select
          dense
          hide-details
          outlined
          class="base-select"
          v-model="formData.profile_id"
          append-icon=""
          :items="listProfileSpace"
          item-text="name"
          item-value="id"
        />
      </div>

      <div class="d-flex align-center mb-4">
        <span class="form__item-text text-left">Рабочее время</span>
        <work-time :isActive="false" v-model="formData.work_time" />
      </div>

      <div class="form__item d-flex align-center mb-4">
        <span class="form__item-text text-left dialog-window__font"
          >Планшет</span
        >
        <input-combo-box
          :dataSelect="listDevicesTablet"
          v-model="formData.tablet"
        />
      </div>

      <div class="form__item d-flex align-center mb-4">
        <span class="form__item-text text-left dialog-window__font"
          >Датчик движения</span
        >
        <input-combo-box
          :dataSelect="listDevicesSensor"
          v-model="formData.sensor"
        />
      </div>
      <div class="form__item d-flex align-center mb-4">
        <div
          class="form__item-text text-left dialog-window__font align-self-start"
        >
          Доп. Оборудование
        </div>
        <v-select
          dense
          hide-details
          outlined
          multiple
          class="base-select"
          :items="listEquipments"
          v-model="formData.equipments_ids"
          style="font-size: 12px;"
          item-text="name"
          item-value="id"
          height="100%"
        >
          <template v-slot:selection="{ item }">
            <div style="width: 100%">{{ item.name }}</div>
          </template>
        </v-select>
      </div>
    </form-placement-layout>
  </v-dialog>
</template>

<script>
import FormPlacementLayout from "./FormPlacementLayout.vue";
import ErrorInfo from "../ErrorInfo.vue";
import InputComboBox from "./DialogAddEditComboBox.vue";
import WorkTime from "./DialogAddEditTime.vue";

import formPlacement from "@/mixins/formPlacement.js";

import convertTime from "@/utils/workTimeObjectToArray.js";
import { convertArray } from "@/utils/workTimeArrayToObject.js";

import { mapActions } from "vuex";

export default {
  name: "FormSpace",
  components: {
    FormPlacementLayout,
    ErrorInfo,
    InputComboBox,
    WorkTime,
  },
  mixins: [formPlacement],
  props: {},
  data() {
    return {
      formData: {
        work_time: [],
        equipments_ids: [],
      },
    };
  },
  mounted() {
    this.init();
    this.$store.dispatch("fetchListDevices");
    this.$store.dispatch("fetchProfiles");
    this.$store.dispatch("fetchSpaceProfile");
    this.$store.dispatch("fetchListEquipments");
  },
  computed: {
    listDevicesTablet() {
      const array = this.$store.getters.getListDevices.filter((el) =>
        el.type === "tablet" &&
        (el.parent_id === this.formData.id || el.parent_id === null)
          ? true
          : false
      );
      array.push({ name: "Отвязать", id: "" });
      return array;
    },
    listDevicesSensor() {
      const array = this.$store.getters.getListDevices.filter((el) =>
        el.type === "sensor" &&
        (el.parent_id === this.formData.id || el.parent_id === null)
          ? true
          : false
      );
      array.push({ name: "Отвязать", id: "", serial: "" });

      return array;
    },
    listProfileSpace() {
      const array = this.$store.getters.getSpaceProfiles;
      return array;
    },
    listEquipments() {
      return this.$store.getters.getListEquipments;
    },
  },
  watch: {
    // auto input equipmnets from profile
    "formData.profile_id": function(newValue, oldValue) {
      if (oldValue === undefined) return;
      console.log(newValue, oldValue);
      // delete old profile equipments
      if (oldValue) {
        // this.deletePrevEquipments(oldValue);
        this.$store
          .dispatch("fetchSProfile", oldValue)
          .then((res) => {
            const oldEquipmentsProfile = res.data.data[0].equipment;

            oldEquipmentsProfile.forEach((equipment) => {
              for (let i = 0; i < this.formData.equipments_ids.length; i++) {
                if (equipment.id === this.formData.equipments_ids[i]) {
                  this.formData.equipments_ids.splice(i, 1);
                  break;
                }
              }
            });
          })
          .then(() => {
            if (newValue) {
              this.setNewEquipments(newValue);
              // this.$store.dispatch("fetchSProfile", newValue).then((res) => {
              //   const NewEquipmentsProfile = res.data.data[0].equipment;
              //   for (let i = 0; i < NewEquipmentsProfile.length; i++) {
              //     if (
              //       this.formData.equipments_ids.find(
              //         (eqID) => eqID === NewEquipmentsProfile[i].id
              //       )
              //     )
              //       break;
              //     this.formData.equipments_ids.push(NewEquipmentsProfile[i].id);
              //   }
              // });
            }
            return;
          });
      }
      // add new equipments from profile
      if (newValue && !oldValue) {
        this.setNewEquipments(newValue);
        this.$store.dispatch("fetchSProfile", newValue).then((res) => {
          const NewEquipmentsProfile = res.data.data[0].equipment;
          for (let i = 0; i < NewEquipmentsProfile.length; i++) {
            if (
              this.formData.equipments_ids.find(
                (eqID) => eqID === NewEquipmentsProfile[i].id
              )
            )
              break;
            this.formData.equipments_ids.push(NewEquipmentsProfile[i].id);
          }
        });
      }
    },
  },
  methods: {
    ...mapActions({
      add: "createSpace",
      edit: "updateSpace",
    }),
    init() {
      this.formData.work_time = convertTime(
        this.$store.getters.getActiveNode[0].work_time
      );
      if (this.data) {
        this.formData = { ...this.data };
        this.formData.work_time = convertTime(this.formData.work_time);
        this.formData.sensor = this.getChildren(
          this.formData.children,
          "sensor"
        );
        this.formData.tablet = this.getChildren(
          this.formData.children,
          "tablet"
        );
        this.formData.equipments_ids = [
          ...this.getEquipments(this.formData.equipment),
        ];
      }
    },
    getEquipments(list) {
      return list
        .filter((item) => (item.type === "equipment" ? item.id : ""))
        .map((item) => item.id);
    },
    getChildren(items, type) {
      if (!items) return null;
      if (items.length <= 0) return null;
      let item = items.find((item) => item.type === type);
      if (!item) return null;
      return item.id;
    },
    submitForm() {
      if (!this.validate()) return;
      const payload = {
        ...(this.action === "edit" ? "" : { parent_id: this.parentId }),
        name: this.formData.name,
        description: this.formData.description,
        capacity: this.formData.capacity,
        sensor_id: this.formData.sensor,
        tablet_id: this.formData.tablet,
        ...(this.action === "edit"
          ? { dots: this.formData.dots }
          : { dots: this.$store.getters.getPolygon.polygonVertices }),
        profile_id: this.formData.profile_id,
        equipment_ids: this.formData.equipments_ids,
        work_time: convertArray(this.formData.work_time),
      };
      if (this.action === "add") {
        this.add(payload)
          .then((response) => {
            this.$store.commit("updatePolygon", []);
            this.$emit("onSubmitForm", response.data.data);
            this.isActive = false;
          })
          .catch((err) => {
            alert(err);
          });
        return;
      }
      if (this.action === "edit") {
        this.edit({
          id: this.formData.id,
          payload: payload,
        })
          .then((response) => {
            this.$store.commit("updatePolygon", []);
            this.$emit("onSubmitForm", response.data.data);
            this.isActive = false;
          })
          .catch((err) => {
            alert(err);
          });
        return;
      }
    },
    validate() {
      this.errorsValidated = {};
      if (!this.formData.name) {
        this.errorsValidated.name =
          "Наименование должно быть от 3 до 127 символов";
      }
      if (!this.formData.capacity) {
        this.errorsValidated.capacity = "Добавьте вместимость";
      }
      if (Object.keys(this.errorsValidated).length == 0) return true;
      return false;
    },
    async deletePrevEquipments(oldValue) {
      await this.$store.dispatch("fetchSProfile", oldValue).then((res) => {
        const oldEquipmentsProfile = res.data.data[0].equipment;

        oldEquipmentsProfile.forEach((equipment) => {
          for (let i = 0; i < this.formData.equipments_ids.length; i++) {
            if (equipment.id === this.formData.equipments_ids[i]) {
              this.formData.equipments_ids.splice(i, 1);
              break;
            }
          }
        });
      });
      console.log("delete old value");
    },
    async setNewEquipments(newValue) {
      await this.$store.dispatch("fetchSProfile", newValue).then((res) => {
        const NewEquipmentsProfile = res.data.data[0].equipment;
        for (let i = 0; i < NewEquipmentsProfile.length; i++) {
          if (
            this.formData.equipments_ids.find(
              (eqID) => eqID === NewEquipmentsProfile[i].id
            )
          )
            break;
          this.formData.equipments_ids.push(NewEquipmentsProfile[i].id);
        }
      });
    },
  },
};
</script>
