<template>
  <v-dialog width="400px" v-model="dialog">
    <v-card tile>
      <v-card-title></v-card-title>
      <v-card-text>
        {{ textInfo }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="accept()"
          class="text-capitalize"
          color="#5FB760"
          elevation="0"
          style="font-size: 12px; color: #fff"
          dense
          >Подтвердить</v-btn
        >
        <v-btn
          @click="cancel()"
          class="text-capitalize"
          color="red darken-1"
          elevation="0"
          style="font-size: 12px; color: #fff"
          dense
          >Отменить</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InfoDialogPromise",
  props: {
    textInfo: {
      type: String,
      default: "",
      require: true,
    },
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
  }),

  methods: {
    open() {
      return new Promise((resolve, reject) => {
        this.dialog = true;
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    accept() {
      this.dialog = false;
      this.resolve(true);
    },
    cancel() {
      this.dialog = false;
      this.reject(false);
    },
  },
};
</script>
