var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (true)?_c('div',{staticClass:"scheme-main__spaces spaces mt-2",staticStyle:{"margin-bottom":"5%"}},[_c('div',{staticClass:"\n      scheme-main__title-font\n      d-flex\n      align-center\n      justify-space-between\n      mb-2\n    "},[_c('span',{staticClass:"black--text",staticStyle:{"font-weight":"700"}},[_vm._v("Пространства")]),_c('v-btn',{attrs:{"color":"green","tile":"","elevation":"0","height":"28","disabled":!_vm.isAllowCreateSpace,"min-width":"40px","max-width":"40px"},on:{"click":_vm.onClickAdd}},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v("+")])])],1),_c('v-data-table',{staticClass:"base-table",attrs:{"dense":"","hide-default-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.value.children},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"item.schedule",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.onShowShedule(item)}}},[_vm._v(" mdi-tablet-dashboard ")])]}},{key:"item.scheme",fn:function(ref){
var item = ref.item;
return [(item.coordinates ? false : true)?_c('v-icon',{on:{"click":function($event){return _vm.onConnetingSpace(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(item.coordinates ? true : false)?_c('v-icon',{on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v(" mdi-close ")]):_vm._e()]}},{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(h,hIndex){return _c('th',{key:'header - ' + hIndex,staticClass:"text-left"},[_c('span',{staticClass:"scheme-main__title-font"},[_vm._v(_vm._s(h.text))])])}),0)])]}}],null,false,4153000761)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }