<template>
  <div>
    <div class="pa-3">
      <h1 class="page-title">
        Список объектов c размещенными устройствами
      </h1>
    </div>

    <!-- <v-container> -->
    <div>
      <resizable-menu>
        <template v-slot:left>
          <v-col>
            <v-simple-table dense fixed-header class="py-2">
              <template v-slot:default>
                <thead>
                  <tr class="d-flex align-center">
                    <th
                      class="text-left text-no-wrap flex-grow-0 flex-shrink-0 d-flex align-center black--text "
                    >
                      Объекты и устройства
                    </th>
                    <!-- d-flex align-center -->
                    <th class="flex-grow-1 flex-shrink-0 d-flex align-center">
                      <!-- style="min-width: 100px; width: 100%" class="mr-2" -->
                      <!-- <div> -->
                      <v-text-field
                        v-model="search_tree"
                        flat
                        dense
                        hide-details
                        outlined
                        full-width
                        class="base-text-field mr-2"
                        style="min-width: 100px"
                      >
                      </v-text-field>
                      <!-- </div> -->
                      <button color="black--text">
                        <span color="black--text">Найти</span>
                      </button>
                    </th>
                  </tr>
                </thead>
              </template>
            </v-simple-table>

            <div class="text-left my-1 mx-9">
              <v-menu min-width="200" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" v-bind="attrs" v-on="on">
                    mdi-plus
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in creationOptions"
                    :key="index"
                    :disabled="item.disabled"
                    @click="onAddObject(item)"
                  >
                    <v-list-item-title>
                      <div class="text-left" style="cursor: pointer">
                        <img :src="`${item.value}`" height="20" width="20" />
                        {{ item.name }}
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-icon class="mr-2" @click.stop="onEditObject">
                mdi-pencil
              </v-icon>
              <v-icon class="mr-2" @click.stop="openConfirmDialog(activeNode)">
                mdi-delete
              </v-icon>
            </div>
            <v-divider />
            <div
              :style="{
                cursor: 'pointer',
                fontSize: '12px',
                height: windowHeight - 200 + 'px',
                overflow: 'auto',
              }"
            >
              <v-treeview
                v-if="treeList"
                height="100%"
                :active.sync="activeTree"
                :items="treeList"
                item-key="id"
                :open.sync="initiallyOpen"
                :search="search_tree"
                :filter="filter_tree"
                activatable
                class="text-left"
                dense
                item-children="children"
                return-object
                @update:active="onActiveNode"
                @update:open="onOpenNode"
              >
                <template v-slot:prepend="{ item }">
                  <img :src="files[item.type].value" height="20" width="20" />
                </template>
                <template v-slot:label="{ item }">
                  <div>{{ item.name }}</div>
                </template>
              </v-treeview>
            </div>
          </v-col>
        </template>
        <template v-slot:right>
          <v-col>
            <v-progress-circular
              v-if="info_loading"
              indeterminate
              color="primary"
            ></v-progress-circular>

            <div
              v-if="activeNode && activeNode.type === 'region'"
              class="text-left description-text"
            >
              <div>
                <span class="font-weight-bold">Наименование:</span>
                {{ activeNode.name }}
              </div>
              <div>
                <span class="font-weight-bold">Описание:</span>
                {{ activeNode.description }}
              </div>
              <div>
                <span class="font-weight-bold">Часовой пояс:</span>
                {{ formatTimeZone(activeNode.timezone) }}
              </div>
              <div class="work-time__table">
                <span class="font-weight-bold">
                  Рабочее время:
                </span>
                <div
                  v-for="(item, idx) in convertDataWork(activeNode.work_time)"
                  :key="idx"
                >
                  <div>
                    {{ item.name }}
                  </div>
                  <div>
                    {{ item.value }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="activeNode && activeNode.type === 'building'"
              class="text-left description-text"
            >
              <div>
                <span class="font-weight-bold">Наименование:</span>
                {{ activeNode.name }}
              </div>
              <div>
                <span class="font-weight-bold">Описание:</span>
                {{ activeNode.description }}
              </div>
              <div>
                <span class="font-weight-bold">Адрес:</span>
                Адрес: {{ activeNode.address }}
              </div>
              <div>
                <span class="font-weight-bold">Часовой пояс:</span>
                {{ formatTimeZone(activeNode.timezone) }}
              </div>
              <div class="work-time__table">
                <span class="font-weight-bold">Рабочее время:</span>
                <div
                  v-for="(item, idx) in convertDataWork(activeNode.work_time)"
                  :key="idx"
                >
                  <div>
                    {{ item.name }}
                  </div>
                  <div>
                    {{ item.value }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                activeNode &&
                  (activeNode.type === 'tablet' || activeNode.type === 'sensor')
              "
              class="text-left description-text"
            >
              <div>
                <span class="font-weight-bold">Тип:</span>
                {{ activeNode.type }}
              </div>
              <div>
                <span class="font-weight-bold">Наименование:</span>
                {{ activeNode.name }}
              </div>
              <div>
                <span class="font-weight-bold">Серийный номер:</span>
                {{ activeNode.serial }}
              </div>
              <div>
                <span class="font-weight-bold">MAC:</span>
                {{ activeNode.mac }}
              </div>
            </div>

            <!-- 
                @onAddDevice="addDevice"
                :isSelectedSchema="activeNode.type"
                 -->
            <scheme-main
              v-if="
                activeNode &&
                  (activeNode.type === 'schema' || activeNode.type === 'space')
              "
              @onAddSpace="onAddObject"
              @onDeleteSpace="handleDeleteSpace"
              :clickOnSpaceTree="isDrawSpace"
              @editedSpace="submitForm"
              v-model="schemaData"
              :currentActive="activeNode"
              :updatedSpace="updatedSpace"
            />
            <div></div>
          </v-col>
        </template>
      </resizable-menu>
    </div>
    <!-- </v-container> -->

    <component
      @onSubmitForm="submitForm"
      v-if="isEditDialog"
      v-model="isEditDialog"
      :is="formComponent"
      :action="typeAction"
      :data="typeAction === 'edit' ? activeNode : null"
      :parentInfo="initFormData"
      :parentId="!!activeNode ? activeNode.id : null"
    />
    <!-- <InfoDialog
      :info-dialog-text="infoDialogText"
      :is-info-dialog="isInfoDialog"
      @accept="isInfoDialog = false"
    /> -->
    <v-snackbar v-model="isInfoDialog" top color="white">
      <span class="black--text">
        {{ infoDialogText }}
      </span>
    </v-snackbar>

    <v-dialog v-model="isConfirmationDialog" width="400px">
      <v-card tile>
        <v-card-title> Внимание </v-card-title>
        <v-card-text>
          {{ confirmationDialogText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="text-capitalize"
            color="#5FB760"
            elevation="0"
            height="29"
            style="font-size: 12px"
            tile
            @click="submitDelete(deleteItem)"
          >
            Да
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="#5FB760"
            elevation="0"
            height="29"
            style="font-size: 12px"
            tile
            @click="closeConfirmDialog"
          >
            Отменить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import region from "../../public/static/region.svg";
import building from "../../public/static/building.png";
import schema from "../../public/static/schema.png";
import space from "../../public/static/place.svg";
import InfoDialog from "@/components/Dialogs/InfoDialog";
import SchemeMain from "@/components/Scheme/SchemeMain.vue";
// import DialogAddEditMain from "../components/Dialogs/DialogsAddEdit/DialogAddEditMain.vue";
import sensor from "../../public/static/motionSensor.svg";
import tablet from "../../public/static/tablet.svg";
import ResizableMenu from "@/components/ResizeWindow/ResizableMenu.vue";

//test
import FormRegion from "../components/Dialogs/DialogsAddEdit/FormRegion.vue";
import FormBuilding from "../components/Dialogs/DialogsAddEdit/FormBuilding.vue";
import FormScheme from "../components/Dialogs/DialogsAddEdit/FormScheme.vue";
import FormSpace from "../components/Dialogs/DialogsAddEdit/FormSpace.vue";
import FormDevice from "../components/Dialogs/DialogsAddEdit/FormDevice.vue";

//js
import convertDataWork from "@/utils/workTimeObjectToArray.js";

export default {
  components: {
    InfoDialog,
    SchemeMain,
    // DialogAddEditMain,
    ResizableMenu,
    FormRegion,
    FormBuilding,
    FormScheme,
    FormSpace,
    FormDevice,
  },
  data: () => ({
    updatedSpace: {},
    initiallyOpen: [],
    activeTree: [],
    files: {
      region: {
        value: region,
        name: "Регион",
        type: "region",
      },
      building: {
        value: building,
        name: "Здание",
        type: "building",
      },
      schema: {
        value: schema,
        name: "Схема",
        type: "schema",
      },
      space: {
        value: space,
        name: "Пространство",
        type: "space",
      },
      tablet: {
        value: tablet,
        name: "Планшет",
        type: "tablet",
      },
      sensor: {
        value: sensor,
        name: "Датчик движ.",
        type: "sensor",
      },
    },
    isEditDialog: false,
    formData: {
      dialogTextValue: "",
      dialogFile: "",
    },
    isInfoDialog: false,
    infoDialogText: "",
    isConfirmationDialog: false,
    confirmationDialogText: "",
    creationOptions: {},

    loading_tree: false,
    info_loading: false,
    //parent_id
    parentId: null,

    //draw space from click tree
    isDrawSpace: false,
    convertDataWork,
    search_tree: null,
    //
    caseSensitive: false,
    formComponent: null,
    //
    deleteItem: null,
    //
    initFormData: {},
    closednode: null,
  }),
  watch: {
    // creationOptions - мы можем создать сущность только следующего уровня вложенности
    // Если выбрано здание - создается схема. Если выбрана схема - создается девайс
    activeNode() {
      if (!this.activeNode) {
        this.setOptions({ region: 0 });
        return;
      }

      if (this.activeNode.type === "region") {
        this.setOptions({ region: 0, building: 0 });
        return;
      }
      if (this.activeNode.type === "building") {
        this.setOptions({ schema: 0 });
        return;
      }
      if (this.activeNode.type === "schema") {
        this.setOptions({ schema: 0, space: 0 });
        if (!this.activeNode.children.length) return;
        if (this.activeNode.children[0].type === "schema") {
          this.setOptions({ schema: 0 });
        }
        if (this.activeNode.children[0].type === "space") {
          this.setOptions({ space: 0 });
        }
        return;
      }
      if (this.activeNode.type === "space") {
        this.setOptions({ TABLET: 0, SENSOR: 0 });
        return;
      }
      if (
        this.activeNode.type === "tablet" ||
        this.activeNode.type === "sensor"
      ) {
        this.setOptions({});
        return;
      }
    },
    /**
     * Если при закрытие ветки дерева в нем был выбран элемент то выделение сбрасывается
     */
    initiallyOpen(newVal, oldVal) {
      if (oldVal.length > newVal.length) {
        let flag = false;
        let el = null;
        for (let i = 0; i < oldVal.length; i++) {
          for (let j = 0; j < newVal.length; j++) {
            if (oldVal[i].id === newVal[j].id) {
              flag = true;
              break;
            }
            el = oldVal[i];
          }
          if (!flag) {
            break;
          }
          flag = false;
        }

        if (el) {
          let closedNode = this.searchNode(el, this.activeNode.id);
          if (closedNode) {
            this.$store.commit("SET_ACTIVE_NODE", {});
            this.activeTree = [{}];
          }
        }
      }
    },
  },
  computed: {
    filter_tree() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    windowHeight() {
      return window.innerHeight;
    },
    activeNode() {
      return this.$store.getters.getActiveNode[0];
    },
    // new logic for work width tree
    treeList() {
      return this.$store.getters.getTree;
    },
    schemaData() {
      return this.$store.getters.getActiveSchema[0];
    },
    // activeDataSchema() {
    //   return this.schemaData.children.find(
    //     (item) => item.id === this.activeNode.id
    //   );
    // },
  },
  methods: {
    handleDeleteSpace(item) {
      console.log("onDeleteSpace = ", item);
    },
    searchNode(Node, id) {
      if ("children" in Node) {
        for (let i = 0; i < Node.children.length; i++) {
          if (Node.children[i].id === id) {
            return Node.children[i];
          }
          return this.searchNode(Node.children[i], id);
        }
      }
      return null;
    },
    onAddObject(item) {
      this.initFormData = {
        type: item.type,
        timezone: this.activeNode ? this.activeNode.timezone : "",
        work_time: this.activeNode ? this.activeNode.work_time : "",
      };
      this.formComponent = this.getFormComponent(item.type);
      this.typeAction = "add";
      this.isEditDialog = true;
    },
    onEditObject() {
      this.initFormData = {
        type: this.activeNode.type,
      };
      this.formComponent = this.getFormComponent(this.activeNode.type);
      this.typeAction = "edit";
      this.isEditDialog = true;
    },
    onActiveNode(node) {
      this.$store.commit("SET_ACTIVE_NODE", node);
      if (!this.activeNode) return;
      if (this.activeNode.type === "schema")
        if (!this.schemaData || this.activeNode.id !== this.schemaData.id)
          this.$store.dispatch("fetchSchema", this.activeNode.id);
      if (this.activeNode.type === "space") {
        if (
          !this.schemaData ||
          this.activeNode.parent_id !== this.schemaData.id
        )
          this.$store.dispatch("fetchSchema", this.activeNode.parent_id);
      }
    },
    onOpenNode() {
      // console.log("on Open Node", node);
    },
    getFormComponent(type) {
      const forms = {
        region: FormRegion,
        building: FormBuilding,
        schema: FormScheme,
        space: FormSpace,
        device: FormDevice,
        sensor: FormDevice,
        tablet: FormDevice,
      };
      return forms[type];
    },
    submitForm(response) {
      console.log("response submit = ", response);

      this.$store.dispatch("fetchTree").then(() => {
        this.activeTree = [{ ...response[0] }];
        this.openTreeNodes({ ...response[0] });
      });

      if (this.activeNode.type === "schema") {
        this.$store.dispatch("fetchSchema", this.activeNode.id).then(() => {
          console.log("response 1= ", response);
          // this.$store.commit("SET_ACTIVE_NODE", response);
          return;
        });
      }
      if (this.activeNode.type === "space") {
        this.$store
          .dispatch("fetchSchema", this.activeNode.parent_id)
          .then(() => {
            console.log("response 2= ", response);
            this.$store.commit("SET_ACTIVE_NODE", response);
            return;
          });
      }
      this.$store.commit("SET_ACTIVE_NODE", response);
    },

    openConfirmDialog(item) {
      this.confirmationDialogText = `Вы действительно хотите удалить ${item.serial ||
        item.name}?`;
      this.deleteItem = item;
      this.isConfirmationDialog = true;
    },

    submitDelete(item) {
      this.isConfirmationDialog = false;
      switch (item.type) {
        case "schema":
          this.infoDialogText = `${item.name} успешно удалена`;
          break;
        case "region":
          this.infoDialogText = `${item.name} успешно удален`;
          break;
        default:
          this.infoDialogText = `${item.serial || item.name} успешно удалено`;
          break;
      }
      this.$store.dispatch("deleteItem", item).then(() => {
        this.$store.dispatch("fetchTree").then(() => {
          this.isInfoDialog = true;

          if (item.type === "space") {
            this.$store.dispatch("fetchSchema", item.parent_id).then(() => {});
          }

          if (item.id === this.activeNode.id) {
            this.$store.commit("SET_ACTIVE_NODE", {});
          }

          this.setNodeAfterDeleted(this.getParentNodeFromInitially(item));
        });
      });
    },
    closeConfirmDialog() {
      this.isConfirmationDialog = false;
    },
    getSchemaImage(item) {
      return this.$store.dispatch("getImage", item.id);
    },

    /**
     * set options
     * @params Object  = { region, building, schema, space, TABLET, SENSOR } : bolean
     */
    setOptions(options) {
      this.creationOptions.region.disabled =
        options.region === undefined ? true : false;
      this.creationOptions.building.disabled =
        options.building === undefined ? true : false;
      this.creationOptions.schema.disabled =
        options.schema === undefined ? true : false;
      this.creationOptions.space.disabled =
        options.space === undefined ? true : false;
      this.creationOptions.tablet.disabled =
        options.TABLET === undefined ? true : false;
      this.creationOptions.sensor.disabled =
        options.SENSOR === undefined ? true : false;
    },
    /**
     *  Форматирование часового пояса добавление его названия
     * @params {string} timeZone - часовой пояс формата UTC+00:00
     */
    formatTimeZone(timeZone) {
      return this.$store.getters.getTimeZone.find(
        (item) => item.value === timeZone
      ).name;
    },
    openTreeNodes(node) {
      if ("parent_id" in node) {
        this.initiallyOpen.push({ id: node.parent_id });
      }
      return;
    },
    /**
     *
     */
    getParentNodeFromInitially(children) {
      return this.initiallyOpen.find((node) => node.id === children.parent_id);
    },
    setNodeAfterDeleted(node) {
      if (!node) return;
      this.activeTree = [{ ...node }];
      this.$store.commit("SET_ACTIVE_NODE", [node]);
    },
  },
  mounted() {
    this.$store.dispatch("fetchTree");
    this.creationOptions = { ...this.files };
    this.setOptions({ region: 0 });
  },
};
</script>

<style lang="scss" scoped>
.work-time__table {
  display: flex;
  gap: 2em;
}
</style>
